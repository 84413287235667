/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ScanSignatureResponse } from '../models/scan-signature-response';
import { ScanSignatureEntry } from '../models/scan-signature-entry';
import { SetSignatureForQaChecksEntry } from '../models/set-signature-for-qa-checks-entry';
import { ResetSignatureEntry } from '../models/reset-signature-entry';
import { EmployeeSignatureViewModel } from '../models/employee-signature-view-model';
import { EmployeeViewModel } from '../models/employee-view-model';
@Injectable({
  providedIn: 'root'
})
class EmployeeSignatureService extends __BaseService {
  static readonly scanSignaturePath = '/api/v1/ScanSignature';
  static readonly setSignatureForQaChecksPath = '/api/v1/SetSignatureForQaChecks';
  static readonly resetSignaturePath = '/api/v1/ResetSignature';
  static readonly getEmployeeSignaturePath = '/api/v1/GetEmployeeSignature/{employeeSignatureId}';
  static readonly getEmployeesForSignaturePath = '/api/v1/GetEmployeesForSignature';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param entry undefined
   */
  scanSignatureResponse(entry: ScanSignatureEntry): __Observable<__StrictHttpResponse<ScanSignatureResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/ScanSignature`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScanSignatureResponse>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  scanSignature(entry: ScanSignatureEntry): __Observable<ScanSignatureResponse> {
    return this.scanSignatureResponse(entry).pipe(__map((_r) => _r.body as ScanSignatureResponse));
  }

  /**
   * @param entry undefined
   */
  setSignatureForQaChecksResponse(entry: SetSignatureForQaChecksEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/SetSignatureForQaChecks`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  setSignatureForQaChecks(entry: SetSignatureForQaChecksEntry): __Observable<null> {
    return this.setSignatureForQaChecksResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param entry undefined
   */
  resetSignatureResponse(entry: ResetSignatureEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/ResetSignature`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  resetSignature(entry: ResetSignatureEntry): __Observable<null> {
    return this.resetSignatureResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param employeeSignatureId undefined
   */
  getEmployeeSignatureResponse(employeeSignatureId: number): __Observable<__StrictHttpResponse<EmployeeSignatureViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/GetEmployeeSignature/${encodeURIComponent(String(employeeSignatureId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSignatureViewModel>;
      })
    );
  }
  /**
   * @param employeeSignatureId undefined
   */
  getEmployeeSignature(employeeSignatureId: number): __Observable<EmployeeSignatureViewModel> {
    return this.getEmployeeSignatureResponse(employeeSignatureId).pipe(__map((_r) => _r.body as EmployeeSignatureViewModel));
  }
  getEmployeesForSignatureResponse(): __Observable<__StrictHttpResponse<EmployeeViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/GetEmployeesForSignature`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeViewModel>;
      })
    );
  }
  getEmployeesForSignature(): __Observable<EmployeeViewModel> {
    return this.getEmployeesForSignatureResponse().pipe(__map((_r) => _r.body as EmployeeViewModel));
  }
}

module EmployeeSignatureService {}

export { EmployeeSignatureService };
