<p-dropdown
  #pDropdown
  [appendTo]="appendTo"
  [options]="currentOptions"
  [group]="grouped"
  [style]="inlineStyle"
  [(ngModel)]="selectedValue"
  (ngModelChange)="onModelChange()"
  [disabled]="disabled"
  (onBlur)="onBlur()"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [dropdownIcon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
  (onShow)="focusOption(pDropdown)"
  [virtualScroll]="virtualScroll"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [panelStyle]="panelWidth"
  [showClear]="!(placeholder === undefined || placeholder === null)"
></p-dropdown>
