import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUnitId'
})
export class FormatUnitIdPipe implements PipeTransform {
  transform(value: string | null): string {
    return value?.toUpperCase() || '';
  }
}
