<p-table
  #dt
  [value]="workCenters$ | async"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  columnResizeMode="expand"
  [styleClass]="'work-center-table'"
  [rows]="10"
  [paginator]="true"
>
  <!-- dataKey="workCenterId" -->
  <ng-template pTemplate="header">
    <tr class="work-center-table__header-row">
      <th
        *ngFor="let col of columns"
        [style.width]="col.width"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
        [pResizableColumnDisabled]="!col.resizable"
        [class]="col.styleClass"
        pResizableColumn
      >
        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workCenter let-rowIndex="rowIndex">
    <tr [formGroup]="formGroup.get('rows').get(rowIndex.toString())" class="work-center-table__order-row">
      <td class="tiny-cell padding-left">
        {{ workCenter.siteName }}
      </td>
      <td class="tiny-cell">
        {{ workCenter.type }}
      </td>
      <td class="tiny-cell">
        {{ workCenter.externalWorkCenterId }}
      </td>
      <td class="medium-cell">
        {{ workCenter.workCenterName }}
      </td>
      <td class="tiny-cell">
        {{ workCenter.workCenterGroupName }}
      </td>

      <!-- operation type list -->
      <td class="medium-cell" [ngClass]="{ required: checkValidity(rowIndex, 'operationTypeId') }">
        <ng-container *ngIf="editAll; else operationType">
          <p-dropdown
            [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
            formControlName="operationTypeId"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.OperationType)"
            [options]="operationTypeList"
            appendTo="body"
            [dropdownIcon]="operationLoading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
            [style]="{ width: '80%' }"
          />
        </ng-container>
        <ng-template #operationType>
          {{ workCenter.operationTypeName }}
        </ng-template>
      </td>

      <!-- process Per Quantity -->
      <td
        class="number-cell"
        [ngClass]="{ required: checkValidity(rowIndex, 'processPerQuantity', WORKCENTER_COLUMN_TYPE.ProcessPerQuantity) }"
      >
        <ng-container *ngIf="editAll; else viewProcessPerQuantity">
          <lib-input-number
            [size]="5"
            [format]="true"
            [min]="INPUT_MIN_NUMBER"
            [max]="INPUT_MAX_NUMBER"
            formControlName="processPerQuantity"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.ProcessPerQuantity)"
            (onBlur)="updateWorkCenterStore()"
          ></lib-input-number>
        </ng-container>
        <ng-template #viewProcessPerQuantity>
          {{ workCenter.processPerQuantity | formatQuantity : false }}
        </ng-template>
      </td>

      <!-- declared preformance -->
      <td
        class="number-cell"
        [ngClass]="{ required: checkValidity(rowIndex, 'declaredPerformance', WORKCENTER_COLUMN_TYPE.DeclaredPerformance) }"
      >
        <ng-container *ngIf="editAll; else viewDeclaredPerformance">
          <lib-input-number
            [size]="5"
            [format]="true"
            [min]="INPUT_MIN_NUMBER"
            [max]="INPUT_MAX_NUMBER"
            formControlName="declaredPerformance"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.DeclaredPerformance)"
            (onBlur)="updateWorkCenterStore()"
          ></lib-input-number>
        </ng-container>
        <ng-template #viewDeclaredPerformance>
          {{ workCenter.declaredPerformance | formatQuantity : false }}
        </ng-template>
      </td>

      <!-- counter unit -->
      <td class="medium-cell" [ngClass]="{ required: checkValidity(rowIndex, 'counterUnitId') }">
        <ng-container *ngIf="editAll; else viewCounterUnit">
          <input
            pInputText
            type="text"
            formControlName="counterUnitId"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.CounterUnit)"
            required
            class="inputtextbox"
          />
        </ng-container>
        <ng-template #viewCounterUnit>
          {{ workCenter.counterUnitId }}
        </ng-template>
      </td>

      <!-- input Warehouse LocationId -->
      <td class="medium-cell">
        <ng-container *ngIf="editAll; else inputLocation">
          <span class="variable-text">{{ workCenter.inputWarehouseLocationExternalId }}</span>
          <span class="icon-style">
            <lib-icon-button
              [iconStyleClass]="'icon-style'"
              [iconClass]="'icon-edit'"
              (clicked)="changeWarehouseLocation(workCenter, true)"
            ></lib-icon-button>
          </span>
        </ng-container>
        <ng-template #inputLocation>
          {{ workCenter.inputWarehouseLocationExternalId }}
        </ng-template>
      </td>

      <!-- output Warehouse LocationId -->
      <td class="medium-cell">
        <ng-container *ngIf="editAll; else outputLocation">
          <span class="variable-text">{{ workCenter.outputWarehouseLocationExternalId }}</span>
          <span class="icon-style">
            <lib-icon-button
              [iconStyleClass]="'icon-style'"
              [iconClass]="'icon-edit'"
              (clicked)="changeWarehouseLocation(workCenter, false)"
            ></lib-icon-button>
          </span>
        </ng-container>
        <ng-template #outputLocation>
          {{ workCenter.outputWarehouseLocationExternalId }}
        </ng-template>
      </td>
      <!-- shift calendar list -->
      <td class="medium-cell">
        <ng-container *ngIf="editAll; else shiftCalendar">
          <p-dropdown
            [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
            [options]="shiftCalendarList"
            appendTo="body"
            formControlName="shiftCalendarId"
            [style]="{ width: '100%' }"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.ShiftCalendarExternal)"
          />
        </ng-container>
        <ng-template #shiftCalendar>
          {{ workCenter.shiftCalendarExternalId }}
        </ng-template>
      </td>

      <!-- workcenter state  -->
      <td class="tiny-cell">
        <ng-container *ngIf="editAll; else workCenterActiveFlag">
          <p-dropdown
            [options]="optionsForActiveInActive"
            appendTo="body"
            formControlName="isActive"
            [dropdownIcon]="loading ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
            [style]="{ width: '80%' }"
            (ngModelChange)="updateWorkCenter($event, workCenter, WORKCENTER_COLUMN_TYPE.ActiveInActive)"
          />
        </ng-container>
        <ng-template #workCenterActiveFlag>
          {{ workCenter.isActive === true ? 'TRUE' : 'FALSE' }}
        </ng-template>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="columns.length">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
