/* tslint:disable */
type MachineState = 'Unknown' | 'Idle' | 'Setup' | 'Run' | 'Downtime' | 'ManualMachine';
module MachineState {
  export const UNKNOWN: MachineState = 'Unknown';
  export const IDLE: MachineState = 'Idle';
  export const SETUP: MachineState = 'Setup';
  export const RUN: MachineState = 'Run';
  export const DOWNTIME: MachineState = 'Downtime';
  export const MANUAL_MACHINE: MachineState = 'ManualMachine';
  export function values(): MachineState[] {
    return [UNKNOWN, IDLE, SETUP, RUN, DOWNTIME, MANUAL_MACHINE];
  }
}

export { MachineState };
