import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomColumn } from '@bd/shared/utils';
import { Table as workCenterTable } from '@bd/shared/models';
import { WorkCenterListViewModel as WorkCenter } from 'chronos-basedata-client';
import { ListValue, LogService } from 'chronos-shared';
import { WorkCenterDsService } from '@bd/core/data-services/work-center-ds';
import { finalize, map, tap } from 'rxjs/operators';
import { WorkCenterQuery } from '@bd/core/global-state/work-center/tasks/workcenter.query';
import { WorkCenterService } from '@bd/core/global-state/work-center/tasks/workcenter.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ChangeWarehouseComponent } from '../change-warehouse/change-warehouse.component';
import { TranslateService } from '@ngx-translate/core';
import { WorkCenterColumnType } from '@bd/modules/work-center/models';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { WarehouseDsService } from '@bd/core/data-services/ware-house-ds.service';

@Component({
  selector: 'app-edit-workcenter-details',
  templateUrl: './edit-workcenter-details.component.html',
  styleUrls: ['./edit-workcenter-details.component.scss']
})
export class EditWorkcenterDetailsComponent implements OnInit {
  @Input() public workCenters$: Observable<WorkCenter[]>;
  @Output() public updateWorkCenterEvent = new EventEmitter();
  @Output() formValidityChange = new EventEmitter<boolean>();

  public warehouseLocationList: ListValue[] = [];
  public operationTypeList: ListValue[] = [];
  public shiftCalendarList: ListValue[] = [];
  public operationLoading = true;
  public locationLoading = true;
  public loading = true;
  public workCenterColumnType: WorkCenterColumnType;
  public readonly WORKCENTER_COLUMN_TYPE = WorkCenterColumnType;
  public formGroup: FormGroup;
  public editAll: boolean = false;
  public readonly INPUT_MIN_NUMBER = 1;
  public readonly INPUT_MAX_NUMBER = 999999;

  private editingRow: WorkCenter | null = null;
  private workCenterList: WorkCenter[];
  columns: workCenterTable[] = [
    new CustomColumn('siteName', 'Site', { styleClass: 'tiny-cell padding-left' }),
    new CustomColumn('type', 'Type', { styleClass: 'tiny-cell' }),
    new CustomColumn('externalWorkCenterId', 'Work Center', { styleClass: 'tiny-cell' }),
    new CustomColumn('workCenterName', 'Name', { styleClass: 'medium-cell' }),
    new CustomColumn('workCenterGroupName', 'Group Name', { styleClass: 'medium-cell' }),
    new CustomColumn('operationTypeName', 'Operation Type', { styleClass: 'tiny-cell' }),
    new CustomColumn('processPerQuantity', 'Process per Quantity', { styleClass: 'medium-cell number-cell' }),
    new CustomColumn('declaredPerformance', 'Performance', { styleClass: 'medium-cell number-cell' }),
    new CustomColumn('counterUnitId', 'Counter Unit', { styleClass: 'tiny-cell' }),
    new CustomColumn('inputWarehouseLocationId', 'Input Warehouse Location', { styleClass: 'tiny-cell' }),
    new CustomColumn('outputWarehouseLocationId', 'Output Warehouse Location', { styleClass: 'tiny-cell' }),
    new CustomColumn('shiftCalendarId', 'Shift Calender', { styleClass: 'medium-cell' }),
    new CustomColumn('isActive', 'Is Active', { styleClass: 'tiny-cell' })
    // new ButtonColumn('actions', 'Edit/Update', { styleClass: 'action-cell' })
  ];

  optionsForActiveInActive = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  constructor(
    public workCenterQuery: WorkCenterQuery,
    private workCenterDsService: WorkCenterDsService,
    private warehouseDsService: WarehouseDsService,
    private workCenterService: WorkCenterService,
    private dialogService: DialogService,
    private translationService: TranslateService,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.getOperationTypes();
    this.getShiftCalendar();
    this.destructureWorkCenters();

    console.info(this.columns);

    this.formGroup = this.fb.group({
      rows: this.fb.array([])
    });

    this.workCenters$.subscribe((workCenters) => {
      this.setFormRows(workCenters);
      this.workCenterList = workCenters;
    });

    this.formGroup.statusChanges.subscribe(() => {
      this.formValidityChange.emit(this.formGroup.valid);
    });
  }

  public onWorkCenterSave(row: WorkCenter) {
    this.editingRow = { ...row }; // Create a mutable copy
    if (this.editingRow) {
      this.workCenterDsService.updateWorkCenters([this.editingRow]).subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.WORKCENTER_UPDATED_SUCCESSFULLY');
        this.updateWorkCenterEvent.emit();
      });
    }
  }

  public onWorkCenterEditInit(row: WorkCenter) {
    this.getWarehouseLocation(row.siteId);
    this.editingRow = { ...structuredClone(row) };
  }

  public onWorkCenterEditOrCancel() {
    this.editingRow = null; // Clear editing row
  }

  public updateWorkCenter(newValue, workCenter: WorkCenter, workCenterColumnType: WorkCenterColumnType) {
    switch (workCenterColumnType) {
      case WorkCenterColumnType.OperationType:
        const operationName = this.operationTypeList.find((items) => items.value === newValue);

        workCenter.operationTypeId = newValue;
        workCenter.operationTypeName = operationName.label;
        break;
      case WorkCenterColumnType.ProcessPerQuantity:
        if (workCenter.processPerQuantity === null) {
          workCenter.processPerQuantity = {};
          workCenter.processPerQuantity.value = newValue;
        } else {
          workCenter.processPerQuantity.value = newValue;
        }
        break;
      case WorkCenterColumnType.DeclaredPerformance:
        if (!workCenter.declaredPerformance) {
          workCenter.declaredPerformance = {};
          workCenter.declaredPerformance.value = newValue;
          workCenter.declaredPerformance.unitId = 'SHT';
        } else {
          workCenter.declaredPerformance.value = newValue;
          workCenter.declaredPerformance.unitId = 'SHT';
        }
        break;
      case WorkCenterColumnType.CounterUnit:
        workCenter.counterUnitId = newValue;
        break;
      case WorkCenterColumnType.ActiveInActive:
        workCenter.isActive = newValue;
        break;
      case WorkCenterColumnType.ShiftCalendarExternal:
        workCenter.shiftCalendarId = newValue;
        break;
      default:
        console.info('default case executed');
        break;
    }

    // update list
    this.updateWorkcenterList(workCenter);
  }

  public changeWarehouseLocation(workCenter, isInputWarehouse: boolean) {
    this.dialogService
      .open(ChangeWarehouseComponent, {
        data: {
          workCenter,
          isInputWarehouse
        },
        header:
          isInputWarehouse === true
            ? this.translationService.instant('WORK_CENTER_SCREEN.EDIT_IN_WAREHOUSE')
            : this.translationService.instant('WORK_CENTER_SCREEN.EDIT_OUT_WAREHOUSE')
      })
      .onClose.subscribe((data) => {
        this.updateWorkcenterList(data);
        this.updateWorkCenterStore();
      });
  }

  public onSaveAll() {
    this.workCenterDsService.updateWorkCenters(this.workCenterList).subscribe(() => {
      LogService.success('SUCCESS_MESSAGE.WORKCENTER_UPDATED_SUCCESSFULLY');
      this.updateWorkCenterEvent.emit();
    });
  }

  public onEditAll() {
    this.editAll = true;
  }

  public onCancelEdit() {
    this.editAll = false;
  }

  public checkValidity(rowIndex: number, columnName: string, workCenterColumnType?: WorkCenterColumnType): boolean {
    if (this.editAll === false) return false;

    const control = this.formGroup.get('rows')?.get(rowIndex.toString())?.get(columnName);

    if (!control) {
      return false;
    }

    if (
      workCenterColumnType === WorkCenterColumnType.ProcessPerQuantity ||
      workCenterColumnType === WorkCenterColumnType.DeclaredPerformance
    ) {
      return control.value === null;
    }

    return control.invalid;
  }

  public updateWorkCenterStore() {
    this.workCenterService.updateWorkCenters(this.workCenterList);
  }

  private setFormRows(workCenters: any[]) {
    const rows = this.formGroup.get('rows') as FormArray;
    rows.clear();

    workCenters.forEach((dataCenter) => {
      rows.push(this.createRow(dataCenter));
    });
  }

  private createRow(workCenter): FormGroup {
    return this.fb.group({
      operationTypeId: [workCenter.operationTypeId, Validators.required],
      processPerQuantity: [workCenter.processPerQuantity?.value, Validators.required],
      declaredPerformance: [workCenter.declaredPerformance?.value, Validators.required],
      counterUnitId: [workCenter.counterUnitId, Validators.required],
      shiftCalendarId: [workCenter.shiftCalendarId, Validators.required],
      isActive: [workCenter.isActive, Validators.required]
    });
  }

  get rows(): FormArray {
    return this.formGroup.get('rows') as FormArray;
  }

  private updateWorkcenterList(workCenter: WorkCenter): void {
    const index = this.workCenterList.findIndex((item) => item.workCenterId === workCenter?.workCenterId);
    if (index !== -1) {
      this.workCenterList[index] = workCenter;
    } else {
      console.info('Item not found');
    }
  }

  private destructureWorkCenters(): void {
    this.workCenters$ = this.workCenters$.pipe(
      map((workCenters) =>
        workCenters.map((item) => ({
          ...structuredClone(item)
        }))
      )
    );
  }

  private getOperationTypes() {
    this.operationLoading = true;
    this.workCenterDsService
      .getOperationTypes()
      .pipe(
        tap((items) => {
          this.operationTypeList = items.map((item) => ({
            label: `${item.operationTypeName}`,
            value: item.operationTypeId
          }));
        }),
        finalize(() => {
          this.operationLoading = false;
        })
      )
      .subscribe();
  }

  private getShiftCalendar() {
    this.loading = true;
    return this.workCenterDsService
      .getShiftCalendars()
      .pipe(
        tap((items) => {
          this.shiftCalendarList = items.map((item) => ({
            label: `${item.externalShiftCalendarId}`,
            value: item.shiftCalendarId
          }));
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }

  private getWarehouseLocation(siteId?: number) {
    this.locationLoading = true;

    return this.warehouseDsService
      .getWarehouseLocations(siteId)
      .pipe(
        tap((items) => {
          this.warehouseLocationList = items.map((item) => ({
            label: `${item.warehouseLocationName}`,
            value: item.warehouseLocationId
          }));
        }),
        finalize(() => {
          this.locationLoading = false;
        })
      )
      .subscribe();
  }
}
