/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SetQaCheckResultEntry } from '../models/set-qa-check-result-entry';
import { CreateManualQaChecksEntry } from '../models/create-manual-qa-checks-entry';
import { SimpleValueEntry } from '../models/simple-value-entry';
import { CreateSamplingDataForProducedMaterialEntry } from '../models/create-sampling-data-for-produced-material-entry';
import { CreateSamplingDataForRunEntry } from '../models/create-sampling-data-for-run-entry';
import { ProductionQaCheck } from '../models/production-qa-check';
import { QaCheckHeaderInfo } from '../models/qa-check-header-info';
import { QaCheck } from '../models/qa-check';
import { AllProductionQaChecks } from '../models/all-production-qa-checks';
import { AllProductionQaChecksFromPreviousOrder } from '../models/all-production-qa-checks-from-previous-order';
import { PeriodicQaCheck } from '../models/periodic-qa-check';
import { SamplingDataListViewModel } from '../models/sampling-data-list-view-model';
@Injectable({
  providedIn: 'root'
})
class QaCheckService extends __BaseService {
  static readonly setCheckResultPath = '/api/v1/QaCheck/{workCenterId}/SetCheckResult/{productionQaCheckId}';
  static readonly setAllQaChecksForSetupPath = '/api/v1/QaCheck/{workCenterId}/SetAllQaChecksForSetup/{productionOrderId}';
  static readonly setAllQaChecksForProducedMaterialPath =
    '/api/v1/QaCheck/{workCenterId}/SetAllQaChecksForProducedMaterial/{producedMaterialId}';
  static readonly setAllQaChecksForPeriodicPath = '/api/v1/QaCheck/{workCenterId}/SetAllQaChecksForPeriodic/{periodicQaCheckId}';
  static readonly createManualQaChecksPath = '/api/v1/QaCheck/{workCenterId}/CreateManualQaChecks/{productionOrderId}';
  static readonly setQaInfoPath = '/api/v1/QaCheck/{productionOrderId}/SetQaInfo';
  static readonly createSamplingDataForProducedMaterialPath =
    '/api/v1/QaCheck/{workCenterId}/CreateSamplingDataForProducedMaterial/{producedMaterialId}';
  static readonly createSamplingDataForRunPath = '/api/v1/QaCheck/{workCenterId}/CreateSamplingDataForRun/{runId}';
  static readonly getSetupProductionQaChecksAllPath = '/api/v1/QaCheck/GetForSetup/{productionOrderId}';
  static readonly getQaCheckerHeaderPath = '/api/v1/QaCheck/{producedMaterialId}/GetHeaderForProducedMaterial';
  static readonly getQaChecksForContainerPath = '/api/v1/QaCheck/{productionOrderId}/GetContainerQaCheck';
  static readonly getAllQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetAllQaChecks';
  static readonly getAllQaChecksFromPreviousOrdersPath = '/api/v1/QaCheck/{productionOrderId}/GetAllQaChecksFromPreviousOrders';
  static readonly getManualProductionQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetManualProductionQaChecks';
  static readonly getActivePeriodicCheckPath = '/api/v1/QaCheck/{productionOrderId}/GetActivePeriodicCheck';
  static readonly getSetupProductionQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetSetupProductionQaChecks';
  static readonly getContainerProductionQaChecksPath = '/api/v1/QaCheck/{containerId}/GetContainerProductionQaChecks';
  static readonly getLineClearanceQaChecksPath = '/api/v1/QaCheck/{productionOrderId}/GetLineClearanceQaChecks';
  static readonly getSamplingDataPath = '/api/v1/QaCheck/{productionOrderId}/GetSamplingData';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `QaCheckService.SetCheckResultParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionQaCheckId`:
   *
   * - `entry`:
   */
  setCheckResultResponse(params: QaCheckService.SetCheckResultParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/SetCheckResult/${encodeURIComponent(
          String(params.productionQaCheckId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.SetCheckResultParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionQaCheckId`:
   *
   * - `entry`:
   */
  setCheckResult(params: QaCheckService.SetCheckResultParams): __Observable<null> {
    return this.setCheckResultResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.SetAllQaChecksForSetupParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setAllQaChecksForSetupResponse(params: QaCheckService.SetAllQaChecksForSetupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/SetAllQaChecksForSetup/${encodeURIComponent(
          String(params.productionOrderId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.SetAllQaChecksForSetupParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setAllQaChecksForSetup(params: QaCheckService.SetAllQaChecksForSetupParams): __Observable<null> {
    return this.setAllQaChecksForSetupResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.SetAllQaChecksForProducedMaterialParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  setAllQaChecksForProducedMaterialResponse(
    params: QaCheckService.SetAllQaChecksForProducedMaterialParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/SetAllQaChecksForProducedMaterial/${encodeURIComponent(
          String(params.producedMaterialId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.SetAllQaChecksForProducedMaterialParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  setAllQaChecksForProducedMaterial(params: QaCheckService.SetAllQaChecksForProducedMaterialParams): __Observable<null> {
    return this.setAllQaChecksForProducedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.SetAllQaChecksForPeriodicParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `periodicQaCheckId`:
   *
   * - `entry`:
   */
  setAllQaChecksForPeriodicResponse(params: QaCheckService.SetAllQaChecksForPeriodicParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/SetAllQaChecksForPeriodic/${encodeURIComponent(
          String(params.periodicQaCheckId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.SetAllQaChecksForPeriodicParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `periodicQaCheckId`:
   *
   * - `entry`:
   */
  setAllQaChecksForPeriodic(params: QaCheckService.SetAllQaChecksForPeriodicParams): __Observable<null> {
    return this.setAllQaChecksForPeriodicResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.CreateManualQaChecksParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  createManualQaChecksResponse(params: QaCheckService.CreateManualQaChecksParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/CreateManualQaChecks/${encodeURIComponent(
          String(params.productionOrderId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.CreateManualQaChecksParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  createManualQaChecks(params: QaCheckService.CreateManualQaChecksParams): __Observable<null> {
    return this.createManualQaChecksResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.SetQaInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setQaInfoResponse(params: QaCheckService.SetQaInfoParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(params.productionOrderId))}/SetQaInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.SetQaInfoParams` containing the following parameters:
   *
   * - `productionOrderId`:
   *
   * - `entry`:
   */
  setQaInfo(params: QaCheckService.SetQaInfoParams): __Observable<null> {
    return this.setQaInfoResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.CreateSamplingDataForProducedMaterialParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  createSamplingDataForProducedMaterialResponse(
    params: QaCheckService.CreateSamplingDataForProducedMaterialParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/CreateSamplingDataForProducedMaterial/${encodeURIComponent(
          String(params.producedMaterialId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.CreateSamplingDataForProducedMaterialParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `producedMaterialId`:
   *
   * - `entry`:
   */
  createSamplingDataForProducedMaterial(params: QaCheckService.CreateSamplingDataForProducedMaterialParams): __Observable<null> {
    return this.createSamplingDataForProducedMaterialResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param params The `QaCheckService.CreateSamplingDataForRunParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `runId`:
   *
   * - `entry`:
   */
  createSamplingDataForRunResponse(params: QaCheckService.CreateSamplingDataForRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.entry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/api/v1/QaCheck/${encodeURIComponent(String(params.workCenterId))}/CreateSamplingDataForRun/${encodeURIComponent(
          String(params.runId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QaCheckService.CreateSamplingDataForRunParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `runId`:
   *
   * - `entry`:
   */
  createSamplingDataForRun(params: QaCheckService.CreateSamplingDataForRunParams): __Observable<null> {
    return this.createSamplingDataForRunResponse(params).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param productionOrderId undefined
   */
  getSetupProductionQaChecksAllResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<ProductionQaCheck>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/GetForSetup/${encodeURIComponent(String(productionOrderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionQaCheck>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getSetupProductionQaChecksAll(productionOrderId: number): __Observable<Array<ProductionQaCheck>> {
    return this.getSetupProductionQaChecksAllResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<ProductionQaCheck>));
  }

  /**
   * @param producedMaterialId undefined
   */
  getQaCheckerHeaderResponse(producedMaterialId: number): __Observable<__StrictHttpResponse<QaCheckHeaderInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(producedMaterialId))}/GetHeaderForProducedMaterial`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QaCheckHeaderInfo>;
      })
    );
  }
  /**
   * @param producedMaterialId undefined
   */
  getQaCheckerHeader(producedMaterialId: number): __Observable<QaCheckHeaderInfo> {
    return this.getQaCheckerHeaderResponse(producedMaterialId).pipe(__map((_r) => _r.body as QaCheckHeaderInfo));
  }

  /**
   * @param productionOrderId undefined
   */
  getQaChecksForContainerResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<QaCheck>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetContainerQaCheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<QaCheck>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getQaChecksForContainer(productionOrderId: number): __Observable<Array<QaCheck>> {
    return this.getQaChecksForContainerResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<QaCheck>));
  }

  /**
   * @param productionOrderId undefined
   */
  getAllQaChecksResponse(productionOrderId: number): __Observable<__StrictHttpResponse<AllProductionQaChecks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetAllQaChecks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllProductionQaChecks>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getAllQaChecks(productionOrderId: number): __Observable<AllProductionQaChecks> {
    return this.getAllQaChecksResponse(productionOrderId).pipe(__map((_r) => _r.body as AllProductionQaChecks));
  }

  /**
   * @param productionOrderId undefined
   */
  getAllQaChecksFromPreviousOrdersResponse(
    productionOrderId: number
  ): __Observable<__StrictHttpResponse<AllProductionQaChecksFromPreviousOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetAllQaChecksFromPreviousOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllProductionQaChecksFromPreviousOrder>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getAllQaChecksFromPreviousOrders(productionOrderId: number): __Observable<AllProductionQaChecksFromPreviousOrder> {
    return this.getAllQaChecksFromPreviousOrdersResponse(productionOrderId).pipe(
      __map((_r) => _r.body as AllProductionQaChecksFromPreviousOrder)
    );
  }

  /**
   * @param productionOrderId undefined
   */
  getManualProductionQaChecksResponse(productionOrderId: number): __Observable<__StrictHttpResponse<PeriodicQaCheck>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetManualProductionQaChecks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PeriodicQaCheck>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getManualProductionQaChecks(productionOrderId: number): __Observable<PeriodicQaCheck> {
    return this.getManualProductionQaChecksResponse(productionOrderId).pipe(__map((_r) => _r.body as PeriodicQaCheck));
  }

  /**
   * @param productionOrderId undefined
   */
  getActivePeriodicCheckResponse(productionOrderId: number): __Observable<__StrictHttpResponse<PeriodicQaCheck>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetActivePeriodicCheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PeriodicQaCheck>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getActivePeriodicCheck(productionOrderId: number): __Observable<PeriodicQaCheck> {
    return this.getActivePeriodicCheckResponse(productionOrderId).pipe(__map((_r) => _r.body as PeriodicQaCheck));
  }

  /**
   * @param productionOrderId undefined
   */
  getSetupProductionQaChecksResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<ProductionQaCheck>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetSetupProductionQaChecks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionQaCheck>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getSetupProductionQaChecks(productionOrderId: number): __Observable<Array<ProductionQaCheck>> {
    return this.getSetupProductionQaChecksResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<ProductionQaCheck>));
  }

  /**
   * @param containerId undefined
   */
  getContainerProductionQaChecksResponse(containerId: number): __Observable<__StrictHttpResponse<Array<ProductionQaCheck>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(containerId))}/GetContainerProductionQaChecks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionQaCheck>>;
      })
    );
  }
  /**
   * @param containerId undefined
   */
  getContainerProductionQaChecks(containerId: number): __Observable<Array<ProductionQaCheck>> {
    return this.getContainerProductionQaChecksResponse(containerId).pipe(__map((_r) => _r.body as Array<ProductionQaCheck>));
  }

  /**
   * @param productionOrderId undefined
   */
  getLineClearanceQaChecksResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<ProductionQaCheck>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetLineClearanceQaChecks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProductionQaCheck>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getLineClearanceQaChecks(productionOrderId: number): __Observable<Array<ProductionQaCheck>> {
    return this.getLineClearanceQaChecksResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<ProductionQaCheck>));
  }

  /**
   * @param productionOrderId undefined
   */
  getSamplingDataResponse(productionOrderId: number): __Observable<__StrictHttpResponse<Array<SamplingDataListViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/QaCheck/${encodeURIComponent(String(productionOrderId))}/GetSamplingData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SamplingDataListViewModel>>;
      })
    );
  }
  /**
   * @param productionOrderId undefined
   */
  getSamplingData(productionOrderId: number): __Observable<Array<SamplingDataListViewModel>> {
    return this.getSamplingDataResponse(productionOrderId).pipe(__map((_r) => _r.body as Array<SamplingDataListViewModel>));
  }
}

module QaCheckService {
  /**
   * Parameters for SetCheckResult
   */
  export interface SetCheckResultParams {
    workCenterId: number;
    productionQaCheckId: number;
    entry: SetQaCheckResultEntry;
  }

  /**
   * Parameters for SetAllQaChecksForSetup
   */
  export interface SetAllQaChecksForSetupParams {
    workCenterId: number;
    productionOrderId: number;
    entry: SetQaCheckResultEntry;
  }

  /**
   * Parameters for SetAllQaChecksForProducedMaterial
   */
  export interface SetAllQaChecksForProducedMaterialParams {
    workCenterId: number;
    producedMaterialId: number;
    entry: SetQaCheckResultEntry;
  }

  /**
   * Parameters for SetAllQaChecksForPeriodic
   */
  export interface SetAllQaChecksForPeriodicParams {
    workCenterId: number;
    periodicQaCheckId: number;
    entry: SetQaCheckResultEntry;
  }

  /**
   * Parameters for CreateManualQaChecks
   */
  export interface CreateManualQaChecksParams {
    workCenterId: number;
    productionOrderId: number;
    entry: CreateManualQaChecksEntry;
  }

  /**
   * Parameters for SetQaInfo
   */
  export interface SetQaInfoParams {
    productionOrderId: number;
    entry: SimpleValueEntry;
  }

  /**
   * Parameters for CreateSamplingDataForProducedMaterial
   */
  export interface CreateSamplingDataForProducedMaterialParams {
    workCenterId: number;
    producedMaterialId: number;
    entry: CreateSamplingDataForProducedMaterialEntry;
  }

  /**
   * Parameters for CreateSamplingDataForRun
   */
  export interface CreateSamplingDataForRunParams {
    workCenterId: number;
    runId: number;
    entry: CreateSamplingDataForRunEntry;
  }
}

export { QaCheckService };
