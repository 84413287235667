/* tslint:disable */
type KpiIdentifier =
  | 'Absence'
  | 'Downtime'
  | 'ConsumptionPrimary'
  | 'DifferencePlan'
  | 'GoodQuantity'
  | 'NumberOfStoppers'
  | 'TargetSpeed'
  | 'Waste'
  | 'SetupTime'
  | 'RunTime'
  | 'Output1'
  | 'Output2'
  | 'KpiOrders'
  | 'ConsumptionSecondary'
  | 'ConsumptionSecondaryLamination'
  | 'NumberOfEmployees'
  | 'Duration'
  | 'BookedHours'
  | 'EmployeeRegistration'
  | 'NumberOfSetups'
  | 'NumberOfPunches'
  | 'AqlSamples'
  | 'TgsSamples';
module KpiIdentifier {
  export const ABSENCE: KpiIdentifier = 'Absence';
  export const DOWNTIME: KpiIdentifier = 'Downtime';
  export const CONSUMPTION_PRIMARY: KpiIdentifier = 'ConsumptionPrimary';
  export const DIFFERENCE_PLAN: KpiIdentifier = 'DifferencePlan';
  export const GOOD_QUANTITY: KpiIdentifier = 'GoodQuantity';
  export const NUMBER_OF_STOPPERS: KpiIdentifier = 'NumberOfStoppers';
  export const TARGET_SPEED: KpiIdentifier = 'TargetSpeed';
  export const WASTE: KpiIdentifier = 'Waste';
  export const SETUP_TIME: KpiIdentifier = 'SetupTime';
  export const RUN_TIME: KpiIdentifier = 'RunTime';
  export const OUTPUT_1: KpiIdentifier = 'Output1';
  export const OUTPUT_2: KpiIdentifier = 'Output2';
  export const KPI_ORDERS: KpiIdentifier = 'KpiOrders';
  export const CONSUMPTION_SECONDARY: KpiIdentifier = 'ConsumptionSecondary';
  export const CONSUMPTION_SECONDARY_LAMINATION: KpiIdentifier = 'ConsumptionSecondaryLamination';
  export const NUMBER_OF_EMPLOYEES: KpiIdentifier = 'NumberOfEmployees';
  export const DURATION: KpiIdentifier = 'Duration';
  export const BOOKED_HOURS: KpiIdentifier = 'BookedHours';
  export const EMPLOYEE_REGISTRATION: KpiIdentifier = 'EmployeeRegistration';
  export const NUMBER_OF_SETUPS: KpiIdentifier = 'NumberOfSetups';
  export const NUMBER_OF_PUNCHES: KpiIdentifier = 'NumberOfPunches';
  export const AQL_SAMPLES: KpiIdentifier = 'AqlSamples';
  export const TGS_SAMPLES: KpiIdentifier = 'TgsSamples';
  export function values(): KpiIdentifier[] {
    return [
      ABSENCE,
      DOWNTIME,
      CONSUMPTION_PRIMARY,
      DIFFERENCE_PLAN,
      GOOD_QUANTITY,
      NUMBER_OF_STOPPERS,
      TARGET_SPEED,
      WASTE,
      SETUP_TIME,
      RUN_TIME,
      OUTPUT_1,
      OUTPUT_2,
      KPI_ORDERS,
      CONSUMPTION_SECONDARY,
      CONSUMPTION_SECONDARY_LAMINATION,
      NUMBER_OF_EMPLOYEES,
      DURATION,
      BOOKED_HOURS,
      EMPLOYEE_REGISTRATION,
      NUMBER_OF_SETUPS,
      NUMBER_OF_PUNCHES,
      AQL_SAMPLES,
      TGS_SAMPLES
    ];
  }
}

export { KpiIdentifier };
