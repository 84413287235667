/* tslint:disable */
type DocumentType =
  | 'Others'
  | 'Image'
  | 'CAD'
  | 'JobPaper'
  | 'PackagingSpecification'
  | 'Design'
  | 'Tool'
  | 'MesInfo'
  | 'NonConformanceImage'
  | 'NonConformanceDocument';
module DocumentType {
  export const OTHERS: DocumentType = 'Others';
  export const IMAGE: DocumentType = 'Image';
  export const CAD: DocumentType = 'CAD';
  export const JOB_PAPER: DocumentType = 'JobPaper';
  export const PACKAGING_SPECIFICATION: DocumentType = 'PackagingSpecification';
  export const DESIGN: DocumentType = 'Design';
  export const TOOL: DocumentType = 'Tool';
  export const MES_INFO: DocumentType = 'MesInfo';
  export const NON_CONFORMANCE_IMAGE: DocumentType = 'NonConformanceImage';
  export const NON_CONFORMANCE_DOCUMENT: DocumentType = 'NonConformanceDocument';
  export function values(): DocumentType[] {
    return [
      OTHERS,
      IMAGE,
      CAD,
      JOB_PAPER,
      PACKAGING_SPECIFICATION,
      DESIGN,
      TOOL,
      MES_INFO,
      NON_CONFORMANCE_IMAGE,
      NON_CONFORMANCE_DOCUMENT
    ];
  }
}

export { DocumentType };
